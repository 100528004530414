body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #f3f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

#main:after {
  content: "";
  display: block;
}

html,
body {
  font-family: "Helvetica", "Arial", "FreeSans", "Verdana", "Tahoma",
    "Lucida Sans", "Lucida Sans Unicode", "Luxi Sans", sans-serif;
}
.page-main {
  min-height: 100%;
  box-sizing: border-box;
}

.grecaptcha-badge {
  bottom: 10% !important;
}

.cajita .MuiInputBase-root {
  height: 32px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.blurry {
  -webkit-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(5px);
  position: relative;
}
